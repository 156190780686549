// feedbackQuestions.js

export const AlumniFeedbackQuestions = [
    {
      question: " I have become more knowledgeable now in my field of study",
      translation: "நான் இப்போது எனது படிப்புத் துறையில் அதிக திறமையாக உள்ளேன்",
      name: "question1",
    },
    {
      question: "I know the latest developments in my field and I’m up-to-date",
      translation: "எனது துறையில் சமீபத்திய முன்னேற்றங்கள் எனக்குத் தெரியும், நான் புதுப்பித்த நிலையில் இருக்கிறேன்",
      name: "question2",
    },
    {
      question: "Nowadays, I’m more practical in solving issues related to my work/domain",
      translation: "எனது பணி/துறையில் தொடர்பான சிக்கல்களை தீர்ப்பதில் நான் மிகவும் நடைமுறையில் இருக்கிறேன்",
      name: "question3",
    },
    {
      question: "My thoughts are more creative after the completion of my course",
      translation: "எனது படிப்பை முடித்த பிறகு எனது எண்ணங்கள் மிகவும் ஆக்கபூர்வமாக இருக்கின்றன",
      name: "question4",
    },
    {
      question: "I’m more analytical in assessing situations nowadays",
      translation: "சூழ்நிலைகளைக் மதிப்பீடு செய்வதில் நான் மிகவும் பகுத்தறிவு கொண்டவனாக இருக்கிறேன்",
      name: "question5",
    },
    {
      question: "I have become more of a self-learner recently",
      translation: "நான் சமீபகாலமாக சுயமாக கற்க மாறிவிட்டேன்",
      name: "question6",
    },

    {
      question: "I can relate the importance of financial management for my professional life ",
      translation: " எனது வாழ்க்கைக்கான நிதி நிர்வாகத்தின் முக்கியத்துவத்தை என்னால் விவரிக்க முடியும்",
      name: "question7",
    },
    {
      question: "I know my capabilities ",
      translation: " எனது திறன்களை நான் அறிவேன்",
      name: "question8",
    },
    {
      question: " I’m familiar with modern equipment / tools in the field ",
      translation: " துறையில் உள்ள நவீன உபகரணங்கள் / கருவிகளை நான் நன்கு அறிந்திருக்கிறேன்",
      name: "question9",
    },
    {
      question: "  I mingle better with my peer group",
      translation: " நான் எனது சக குழுவுடன் நன்றாக பழகுகிறேன்",
      name: "question10",
    },
    {
      question: "I have more professional friends ",
      translation: " எனக்கு தொழில்முறை நண்பர்கள் அதிகம்",
      name: "question11",
    },
    {
      question: "  I’m talented to lead a group",
      translation: "(நான் ஒரு குழுவை வழிநடத்த திறமையாக உள்ளேன் ",
      name: "question12",
    },
    {
      question: " Over the years, I developed ability to communicate effectively",
      translation: "நான் திறம்பட தொடர்பு கொள்ளும் திறனை வளர்த்துக் கொண்டேன் ",
      name: "question13",
    },
    {
      question: "I can express my ideas clearly to others ",
      translation: " எனது எண்ணங்களை மற்றவர்களுக்கு தெளிவாக வெளிப்படுத்த முடிகிறது",
      name: "question14",
    },
    {
      question: " My actions are more ethical than before",
      translation: "எனது செயல்கள் முன்பை விட ஒழுக்கமானவையாக இருக்கிறது ",
      name: "question15",
    },
    {
      question: " I know my capabilities ",
      translation: "எனது திறன்களை நான் அறிவேன் ",
      name: "question16",
    },

  ];

  // export const collab_fb = [
  //   {

  //     question:"Main objectives of the proposal",
  //     translation:"நோக்கம்",
  //     name:"question 1",

  //   },
  //   {
  //     question: "Review of the state-of-the-art in the field",
  //     translation: "துறையில் கலைநிலை ஆய்வு மதிப்பீடு",
  //     name: "question 2",
  //   },
  //   {
  //     question: "Detailed Methodology and Plan of Action",
  //     translation: "வழிமுறை மற்றும் செயல்திட்டம்",
  //     name: "question 3",
  //   },
  //   {
  //     question: "Performance Milestones and Schedule",
  //     translation: "செயல்திறன் மைல்கல் மற்றும் அட்டவனை",
  //     name: "question 4",
  //   },
  //   {
  //     question: "Deliverables",
  //     translation: "வழங்ககூடியவை",
  //     name: "question 5",
  //   },
  //   {
  //     question: "Estimated Expenditure",
  //     translation: "மதிப்பிடப்பட்ட செலவு",
  //     name: "question 6",
  //   },
  //   {
  //     question: "Appointment of Research Staff in time",
  //     translation: "சரியான நேரத்தில் ஆராய்ச்சி ஊழியர்களை நியமித்தல்",
  //     name: "question 7",
  //   },
  //   {
  //     question: "Completion of Modules in time",
  //     translation: "தொகுதிகளை சரியான நேரத்தில் முடித்தல்",
  //     name: "question 8",
  //   },
  //   {
  //     question: "Result achieved as expected",
  //     translation: "எதிர்பார்த்தபடி முடிவு அடையப்பட்டது",
  //     name: "question 9",
  //   },
  //   {
  //     question: "Performance in periodical Reviews",
  //     translation: "காலமுறை மதிப்பாய்வில் செயல்திறன்",
  //     name: "question 10",
  //   },{
  //     question: "Timely closure of the project",
  //     translation: "திட்டத்தைச் சரியான நேரத்தில் முடிப்பது",
  //     name: "question 11",
  //   },
  //   {
  //     question: "Literature Collected",
  //     translation: "சேகரிக்கப்பட்ட ஆவணங்கள்",
  //     name: "question 12",
  //   },
  //   {
  //     question: "Final Technical Report(s)",
  //     translation: "இறுதி தொழில்நுட்ப அறிக்கை",
  //     name: "question 13",
  //   },
  //   {
  //     question: "Hardware or Software developed",
  //     translation: "மென்பொருள் மற்றும் வன்பொருள்",
  //     name: "question 14",
  //   },
  //   {
  //     question: "Deliverables as in Proposal",
  //     translation: "முன்மொழிவில் உள்ளபடி வழங்ககூடியவை",
  //     name: "question 15",
  //   },{
  //     question: "Publications",
  //     translation: "வெளியீடு",
  //     name: "question 16",
  //   },
  //   {
  //     question: "Patents",
  //     translation: "காப்புரிமை",
  //     name: "question 17",
  //   },

  // ];

//   export const std_teach_fb_data=[
    
//       {
//         question: "I carry the knowledge in Mathematics, Science, and Engineering fundamentals",
//         translation: "",
//         name: "question1"
//       },
//       {
//         question: "I am able to analyze complex engineering problems",
//         translation: "",
//         name: "question2"
//       },
//       {
//         question: "I am able to identify components, design systems, and reach solutions for engineering problems",
//         translation: "",
//         name: "question3"
//       },
//       {
//         question: "I am able to do analysis and interpret the data to provide valid conclusions",
//         translation: "",
//         name: "question4"
//       },
//       {
//         question: "I am able to use modern tools for engineering activities",
//         translation: "",
//         name: "question5"
//       },
//       {
//         question: "I am applying the contextual knowledge to assess several issues and the consequent responsibilities relevant to the profession",
//         translation: "",
//         name: "question6"
//       },
      
//         {
//           question: "I understand the importance of engineering solutions for the development of society and the environment",
//           translation: "",
//           name: "question7"
//         },
//         {
//           question: "I apply ethical principles and commit to professional ethics and responsibilities",
//           translation: "",
//           name: "question8"
//         },
//         {
//           question: "I am able to function effectively as an individual, and as a member or as a leader in diverse teams",
//           translation: "",
//           name: "question9"
//         },
//         {
//           question: "I am able to communicate effectively (write reports, make effective presentations and give, receive clear instructions) with society at large",
//           translation: "",
//           name: "question10"
//         },
//         {
//           question: "I am able to apply the knowledge related to Project management and finance",
//           translation: "",
//           name: "question11"
//         },
//         {
//           question: "I am able to learn new techniques and update myself in the broadest context of technological change",
//           translation: "",
//           name: "question12"
//         },
//         {
//           question: "I am able to analyze, design, and provide solutions to the problems pertaining to Electronics, VLSI, Image processing, and Embedded systems",
//           translation: "",
//           name: "question13"
//         },
//         {
//           question: "I am able to analyze, design, and provide solutions to the problems pertaining to RF and Microwaves, Wireless and Optical Communication",
//           translation: "",
//           name: "question14"
//         },
//         {
//           question: "I am able to design web-based and mobile-based applications",
//           translation: "",
//           name: "question15"
//         },
// ];

export const std_mini_project_fb_data=[
  {
    question:"Objectives of the project were well defined ",
    translation:"",
    name: "question1"
  },
  {
    question:"Extent of technical support by the supervisor to complete the project",
    translation:"",
    name: "question2"
  },
  {
    question:"Extent of facilities offered or available in the department in perusing the project work",
    translation:"",
    name: "question3"
  },
  {
    question:"Project reviews or meetings were conducted regularly",
    translation:"",
    name: "question4"
  },
  {
    question:"Project reviews helped us to enhance the quality and quantity of work",
    translation:"",
    name: "question5"
  },
  {
    question:"Support of supervisor in preparing and evaluating the thesis",
    translation:"",
    name: "question6"
  },
  {
    question:"Did you implement the knowledge gained in developing the project?",
    translation:"",
    name: "question7"
  },
  {
    question:"Did the project work motivate you to do new findings even after completing the course?",
    translation:"",
    name: "question8"
  },
  {
    question:"The project developed is beneficial to the society",
    translation:"",
    name: "question9"
  },
  {
    question:"Whether appreciated and recognized as an individual or as a team for the work done",
    translation:"",
    name: "question10"
  },
];

  // export const student_fb_card=[
  //   {
  //     'id':'1',
  //     'program':'Teaching and Learning ',
  //     'pdf':'./Std_teaching_fb'
  //   },
  //   {
  //     'id':'2',
  //     'program':'Practical and Laboratory Feedback',
  //     'pdf':'####'
  //   },
  //   {
  //     'id':'3',
  //     'program':'Technical Seminar Feedback',
  //     'pdf':'####'
  //   },
  //   {
  //     'id':'4',
  //     'program':'Mini Project and Project Feedback',
  //     'pdf':'./Std_mini_project_fb'
  //   },
   
  // ];



  export const employerFeedbackQuestions = [
    {
      question: "Technical knowledge/skill",
      translation: "தொழில்நுட்ப அறிவு/திறன்",
      name: "technical_knowledge",
    },
    {
      question: "Communication skill",
      translation: "தொடர்பு திறன்",
      name: "communication_skill",
    },
    {
      question: "Independent thinking and problem solving ability",
      translation: "சுய‌ சிந்தனை மற்றும் சிக்கலை தீர்க்கும் திறன்",
      name: "independent_thinking",
    },
    {
      question: "Inclination to adopt new technology",
      translation: "புதிய தொழில்நுட்பத்தை கடைப்பிடிக்க விருப்பம்",
      name: "new_technology_inclination",
    },
    {
      question: "Ability to take up extra responsibility",
      translation: "கூடுதல் பொறுப்பை ஏற்கும் திறன்",
      name: "extra_responsibility",
    },
    {
      question: "Obligation to work beyond schedule if required",
      translation: "தேவைப்பட்டால் அட்டவணைக்கு அப்பால் வேலை செய்ய வேண்டிய கடமை",
      name: "work_beyond_schedule",
    },
    {
      question: "Ability to contribute to the goal of the organization",
      translation: "நிறுவனத்தின் இலக்குக்கு பங்களிக்கும் திறன்",
      name: "organizational_contribution",
    },
    {
      question: "Planning and organization skills",
      translation: "திட்டமிடல் மற்றும் ஒருங்கிணைக்கும் திறன்கள்",
      name: "planning_organization_skills",
    },
    {
      question: "Possess leadership qualities",
      translation: "தலைமை பண்புகளை கொண்டவர்",
      name: "leadership_qualities",
    },
    {
      question: "Relationship with seniors and subordinates",
      translation: "மூத்தவர் மற்றும் துணை அதிகாரிகளுடனான உறவு",
      name: "relationship_with_seniors",
    },
  ];

  
export const parentFeedbackQuestions = [
  {
    question: "Rate the infrastructure facilities of the institution",
    translation: "நிறுவனங்களின் உட்கட்டமைப்பு வசதிகளை மதிப்பிடவும்",
    name: "infrastructure_facilities",
  },
  {
    question: "Rate the ambience of the college with respect to the facilities such as ATM, post office, medical facilities, and transportation given to your ward",
    translation: "தன்னியக்க காசளிப்பு இயந்திரம் (ஏடிஎம்), தபால் அலுவலகம், மருத்துவ வசதிகள், போக்குவரத்து வசதிகளைப் பொருத்து கல்லூரி சூழலை மதிப்பிடவும்",
    name: "college_ambience",
  },
  {
    question: "Authorities and the college office are approachable, ready to help and clarify your queries",
    translation: "அதிகாரிகள் மற்றும் கல்லூரி அலுவலகம் அணுகக்கூடியது, உங்கள் கேள்விகளுக்கு உதவவும் தெளிவுப்படுத்தவும் தயாராக உள்ளது",
    name: "authority_approachability",
  },
  {
    question: "Is your ward happy with the hostel facilities?",
    translation: "உங்கள் பிள்ளைகள் விடுதி வசதிகளால் மகிழ்ச்சியாக உள்ளனரா?",
    name: "hostel_facilities",
  },
  {
    question: "Rate the library and sport facilities",
    translation: "நூலகம் மற்றும் விளையாட்டு வசதிகளை மதிப்பிடவும்",
    name: "library_sports_facilities",
  },
  {
    question: "What is your assessment of security and safety measures in the institution?",
    translation: "கல்வி நிறுவனத்தில் பாதுகாப்பு மற்றும் பாதுகாப்பு நடவடிக்கைகள் பற்றிய உங்கள் மதிப்பீடு என்ன?",
    name: "security_safety_measures",
  },
  {
    question: "Rate the academic skills and knowledge of the faculty",
    translation: "ஆசிரியர்களின் கல்வித்திறன் மற்றும் அறிவை மதிப்பிடுங்கள்",
    name: "faculty_academic_skills",
  },
  {
    question: "Rate the learning experience of your ward in the institution",
    translation: "நிறுவனத்தில் உங்கள் பிள்ளைகளின் கற்றல் அனுபவத்தை மதிப்பிடவும்",
    name: "learning_experience",
  },
  {
    question: "Rate the environment in terms of diversity among the students in the institution",
    translation: "நிறுவனத்தில் உள்ள மாணவர்களிடையே பண்முகத்தன்மையின் அடிப்படையில் மதிப்பிடவும்",
    name: "environment_diversity",
  },
  {
    question: "Assessment about the placement and internship opportunities provided by the institution",
    translation: "நிறுவனம் வழங்கிய வேலைவாய்ப்பு பற்றிய மதிப்பீடு",
    name: "placement_opportunities",
  },
  {
    question: "Technical knowledge of your ward has improved after joining the institution",
    translation: "நிறுவனத்தில் சேர்ந்தபிறகு உங்கள் பிள்ளையின் தொழில்நுட்ப அறிவு மேம்பட்டுள்ளது",
    name: "technical_knowledge_improvement",
  },
  {
    question: "How conducive do you think the college environment is for the overall development of your ward?",
    translation: "உங்கள் பிள்ளையின் ஒட்டுமொத்த வளர்ச்சிக்குக் கல்லூரிச் சூழல் எந்த அளவுக்கு உகந்தது என்று நினைக்கிறீர்கள்",
    name: "college_environment_development",
  },
];

export const collaboratorFeedbackQustions = [
  {
    question:"Main objectives of the proposal",
    translation:"நோக்கம்",
    name:"proposal_objective",
  },
  {
    question:"Review of the state-of-art in the field",
    translation:"துறையில் கலைநிலை ஆய்வு மதிப்பீடு",
    name:"state_of_art",
  },
  {
    question:"Detailed Methodology and Plan of Action",
    translation:"வழிமுறை மற்றும் செயல்திட்டம்",
    name:"methodology_and_action_plan",
  },
  {
    question:"Performance Milestones and Schedule",
    translation:"செயல்திறன் மைல்கல் மற்றும் அட்டவனை",
    name:"performance_milestone",
  },
  {
    question:"Deliverables",
    translation:"வழங்ககூடியவை",
    name:"deliverables",
  },
  {
    question:"Estimated Expenditure",
    translation:"மதிப்பிடப்பட்ட செலவு",
    name:"estimated_expenditure",
  },
  {
    question:"Appointment of Research Staff in time",
    translation:"சரியான நேரத்தில் ஆராய்ச்சி ஊழியர்களை நியமித்தல்",
    name:"appoinment_of_staffs",
  },
  {
    question:"Completion of Modules in time",
    translation:"தொகுதிகளை சரியான நேரத்தில் முடித்தல்",
    name:"completion_in_time",
  },
  {
    question:"Result achieved as expected",
    translation:"எதிர்பார்த்தபடி முடிவு அடையப்பட்டது",
    name:"expected_result",
  },
  {
    question:"Performance in periodical Reviews",
    translation:"காலமுறை மதிப்பாய்வில் செயல்திறன்",
    name:"review_performance",
  },
  {
    question:"Deliverables",
    translation:"வழங்ககூடியவை",
    name:"deliverables",
  },
  {
    question:"Estimated Expenditure",
    translation:"மதிப்பிடப்பட்ட செலவு",
    name:"estimated_expenditure",
  },
  {
    question:"Appointment of Research Staff in time",
    translation:"சரியான நேரத்தில் ஆராய்ச்சி ஊழியர்களை நியமித்தல்",
    name:"appoinment_of_staffs",
  },
  {
    question:"Completion of Modules in time",
    translation:"தொகுதிகளை சரியான நேரத்தில் முடித்தல்",
    name:"completion_in_time",
  },
  {
    question:"Result achieved as expected",
    translation:"எதிர்பார்த்தபடி முடிவு அடையப்பட்டது",
    name:"expected_result",
  },
  {
    question:"Performance in periodical Reviews",
    translation:"காலமுறை மதிப்பாய்வில் செயல்திறன்",
    name:"review_performance",
  },
  {
    question:"Timely closure of the project",
    translation:"திட்டத்தைச் சரியான நேரத்தில் முடிப்பது",
    name:"project_closure_time",
  },
  {
    question:"Literature Collected",
    translation:"சேகரிக்கப்பட்ட ஆவணங்கள்",
    name:"literature_collected",
  },
  {
    question:"Final Technical Report(s)",
    translation:"இறுதி தொழில்நுட்ப அறிக்கை",
    name:"final_technical_report",
  },
  {
    question:"Hardware or Software developed",
    translation:"மென்பொருள் மற்றும் வன்பொருள்",
    name:"developed_hardware_or_software",
  },
  {
    question:"Deliverables as in Proposal",
    translation:"முன்மொழிவில் உள்ளபடி வழங்ககூடியவை",
    name:"proposal_deliverables",
  },
  {
    question:"Publications",
    translation:"வெளியீடு",
    name:"publications",
  },
  {
    question:"Patents",
    translation:"காப்புரிமை",
    name:"patents",
  },
];




export const supplierFeedbackQuestions = [
  {
    question: "Procurement process",
    translation: "கொள்முதல் செயல்முறை",
    name: "procurement_process",
  },
  {
    question: "Payment process",
    translation: "கட்டண செயல்முறை",
    name: "payment_process",
  },
  {
    question: "Staff members’ professionalism",
    translation: "ஊழியகர்களின் தொழில்முறை",
    name: "staff_professionalism",
  },
  {
    question: "Supplier's receipt process",
    translation: "சப்ளையர் ரசீது செயல்முறை",
    name: "receipt_process",
  },
  {
    question: "Paper work process",
    translation: "காகிதவேலை செயல்முறை",
    name: "paperwork_process",
  },
  {
    question: "Ability to provide efficient and effective communication at all levels",
    translation: "அனைத்து நிலைகளில் திறமையான மற்றும் பயனுள்ள தகவல்தொடர்புகளை வழங்குவதர்கான இருப்பு",
    name: "communication_efficiency",
  },
  {
    question: "Levels of ethical practice and support",
    translation: "நெறிமுறை,நடைமுறை மற்றும் ஆதரவு நிலைகள்",
    name: "ethical_practices",
  },
  {
    question: "Business relationship with you",
    translation: "வணிக உறவு",
    name: "business_relationship",
  },
];

export const consultancyFeedbackQuestions = [
  {
    question:"Consultancy Fee     ",
    translation:" நிறுவன கட்டணம் ",
    name:"consultancy_fee",
  },
  {
    question:"Meeting Deadline  ",
    translation:"காலக்கெடு",
    name:"meeting_deadline",
  },
  {
    question:"Work Quality",
    translation:"வேலை தரம்",
    name:"work_quality",
  },
  {
    question:"Approach",
    translation:"அணுகுமுறை",
    name:"approach",
  },
];



  export const std_teach_fb_data=[
    
      {
        question: "I carry the knowledge in Mathematics, Science, and Engineering fundamentals",
        translation: "",
        name: "question1"
      },
      {
        question: "I am able to analyze complex engineering problems",
        translation: "",
        name: "question2"
      },
      {
        question: "I am able to identify components, design systems, and reach solutions for engineering problems",
        translation: "",
        name: "question3"
      },
      {
        question: "I am able to do analysis and interpret the data to provide valid conclusions",
        translation: "",
        name: "question4"
      },
      {
        question: "I am able to use modern tools for engineering activities",
        translation: "",
        name: "question5"
      },
      {
        question: "I am applying the contextual knowledge to assess several issues and the consequent responsibilities relevant to the profession",
        translation: "",
        name: "question6"
      },
      
        {
          question: "I understand the importance of engineering solutions for the development of society and the environment",
          translation: "",
          name: "question7"
        },
        {
          question: "I apply ethical principles and commit to professional ethics and responsibilities",
          translation: "",
          name: "question8"
        },
        {
          question: "I am able to function effectively as an individual, and as a member or as a leader in diverse teams",
          translation: "",
          name: "question9"
        },
        {
          question: "I am able to communicate effectively (write reports, make effective presentations and give, receive clear instructions) with society at large",
          translation: "",
          name: "question10"
        },
        {
          question: "I am able to apply the knowledge related to Project management and finance",
          translation: "",
          name: "question11"
        },
        {
          question: "I am able to learn new techniques and update myself in the broadest context of technological change",
          translation: "",
          name: "question12"
        },
        {
          question: "I am able to analyze, design, and provide solutions to the problems pertaining to Electronics, VLSI, Image processing, and Embedded systems",
          translation: "",
          name: "question13"
        },
        {
          question: "I am able to analyze, design, and provide solutions to the problems pertaining to RF and Microwaves, Wireless and Optical Communication",
          translation: "",
          name: "question14"
        },
        {
          question: "I am able to design web-based and mobile-based applications",
          translation: "",
          name: "question15"
        },
      
  ]
    export const practical_fb_data=[
      {
        question: "Lab manuals are supplied at the beginning of the lab",
        translation: "",
        name: "question1"
      },
      {
        question: "Labs were neat and clean with proper labelling and marking of equipment’s ",
        translation: "",
        name: "question2"
      },
      {
        question: "All equipment’s were in working condition",
        translation: "",
        name: "question3"
      },
      {
        question: "Each student gets a chance to perform the experiment by his/her own  hands",
        translation: "",
        name: "question4"
      },
      {
        question: "Lab hours were mostly spent on performing experiments",
        translation: "",
        name: "question5"
      },
      {
        question: "Faculty was cooperative and ensured the smooth conduct of the experiments",
        translation: "",
        name: "question6"
      },
      {
        question: "Faculty had in-depth knowledge about the experiments",
        translation: "",
        name: "question7"
      },
      {
        question: "Lab reports were checked timely with proper marking or grading",
        translation: "",
        name: "question8"
      },
      {
        question: "Model exams were conducted and helped us to prepare for semester exams",
        translation: "",
        name: "question9"
      },
      {
        question: "Practical sessional exams were held in time",
        translation: "",
        name: "question10"
      },
      {
        question: "Experiments helped in better understanding of the subject",
        translation: "",
        name: "question11"
      },
    ];
    
 
    export const technical_fb_data=[
      {
        question: "Faculty detailed the objectives and the plan of execution of the course to the students ",
        translation: "",
        name: "question1"
      },
      {
        question: "Faculty gave advise in selection and presentation of topics",
        translation: "",
        name: "question2"
      },
      {
        question: "Effectiveness of Faculty in making the students use modern presentation aids",
        translation: "",
        name: "question3"
      },
      {
        question: "Faculty support towards the development of students skill",
        translation: "",
        name: "question4"
      },
      {
        question: "Faculty made the seminar class a positive learning environment",
        translation: "",
        name: "question5"
      },
      {
        question: "Faculty and the course motivated the students do their best work",
        translation: "",
        name: "question6"
      },
      {
        question: "Faculty appreciated the students ideas and presentation",
        translation: "",
        name: "question7"
      },
      {
        question: "Faculty appeared enthusiastic and interested",
        translation: "",
        name: "question8"
      },
      {
        question: "Faculty was accessible outside of class",
        translation: "",
        name: "question9"
      },
      {
        question: "I found the course to be interesting and a platform to learn and present new ideas",
        translation: "",
        name: "question10"
      },
    ]


  export const student_fb_card=[
    {
      'id':'1',
      'program':'Teaching and Learning ',
      'pdf':'./Std_teaching_fb'
    },
    {
      'id':'2',
      'program':'Practical and Laboratory Feedback',
      'pdf':'./Practical_fb'
    },
    {
      'id':'3',
      'program':'Technical Seminar Feedback',
      'pdf':'Technical_fb'
    },
    {
      'id':'4',
      'program':'Mini Project and Project Feedback',
      'pdf':'####'
    },
   
  ]



  